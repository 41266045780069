


































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { apiBargainActivityGoodsLists } from '@/api/marketing/bargain'
import { RequestPaging } from '@/utils/util'
import LsPagination from '@/components/ls-pagination.vue'
@Component({
    components: {
        LsPagination
    }
})
export default class LsGoodsSelect extends Vue {
    // @Prop() value ? : number
    // @Prop() userId ? : number // 用户id
    @Prop({
        default: ''
    })
    title!: string //弹窗标题
    @Prop({
        default: '880px'
    })
    width!: string | number //弹窗的宽度
    @Prop({
        default: '10vh'
    })
    top!: string | number //弹窗的距离顶部位置
    /** S Data **/
    visible = false
    $refs!: {
        formRef: any
    }

    isNameSN = '' // 0-编号， 1-昵称
    // 查询表单
    form = {
        name: '', // 商品名称
        category_id: '' // 分类id
    }
    pager: RequestPaging = new RequestPaging()
    // 被选中的列
    multipleSelection = []
    /** E Data **/

    /** S Methods **/
    // 触发打开弹窗
    onTrigger() {
        this.getList()
        this.visible = true
    }

    // 关闭弹窗
    close() {
        this.visible = false
        // 重制表单内容
        this.form.name = ''
        this.form.category_id = ''
    }

    // 获取选中的列
    handleSelectionChange(val: any) {
        this.multipleSelection = val
    }

    // 重置
    onReset() {
        this.form.name = ''
        this.form.category_id = ''

        this.getList()
    }

    // 确定选中
    onSubmit() {
        if (!this.multipleSelection.length) {
            return this.$message.error('请选择商品')
        }

        const ids = this.multipleSelection.map((item: any) => {
            return item.id
        })
        // 返回选中商品id列表
        this.$emit('getGoods', ids)
        this.visible = false
    }

    // 获取商品列表
    getList(): void {
        this.pager
            .request({
                callback: apiBargainActivityGoodsLists,
                params: {
                    ...this.form
                }
            })
            .then((res: any) => {})
    }
    /** E Methods **/

    /** S Life Cycle **/
    /** E Life Cycle **/
}
