import { render, staticRenderFns } from "./bargain_edit.vue?vue&type=template&id=4d0eb8c5&scoped=true&"
import script from "./bargain_edit.vue?vue&type=script&lang=ts&"
export * from "./bargain_edit.vue?vue&type=script&lang=ts&"
import style0 from "./bargain_edit.vue?vue&type=style&index=0&id=4d0eb8c5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d0eb8c5",
  null
  
)

export default component.exports