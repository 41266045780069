


















































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { PageMode } from '@/utils/type'
import { timeFormat } from '@/utils/util'
import {
    apiBargainActivityGoodsLists,
    apiBargainActivityChooseGoods,
    apiBargainActivityAdd,
    apiBargainActivityEdit,
    apiBargainActivityDetail
} from '@/api/marketing/bargain.ts'
import LsGoodsSelect from '@/components/marketing/bargain/ls-goods-select.vue'
import DatePicker from '@/components/date-picker.vue'
import GoodsSelect from '@/components/goods-select/index.vue'
@Component({
    components: {
        LsGoodsSelect,
        DatePicker,
        GoodsSelect
    }
})
export default class BargainEdit extends Vue {
    // /** S Data **/

    mode: string = PageMode.ADD // 当前页面【add: 添加用户等级 | edit: 编辑用户等级】
    identity: number | null = null // 当前编辑的ID  valid: mode = 'edit'
    status: number | null = null // 当前编辑的状态  valid: statsu = 1-未开始 2-进行中 0-详情
    form: any = {
        name: '', // 砍价活动名称
        start_time: '', // 砍价活动开始时间
        end_time: '', // 砍价活动结束时间
        remark: '', // 备注
        is_distribution: 0, // 是否参与分销 0-否 1-是
        buy_condition: 1, // 购买条件 1-任意金额可购买 2-砍到底价可购买
        valid_period: 0, // 有效期，单位：分钟，多少时间内砍价完成有效
        help_num: 0, // 帮砍人数
        knife_amount_type: 1, // 每刀金额类型 1-固定金额 2-随机金额
        self: 1, // 自己是否可以参与砍价 0-否 1-是
        count: 0, // 每个用户可发起次砍价次数
        buy_limit: 0, // 起购限制
        order_limit: 0, // 每单限制
        use_coupon: 0, // 是否允许使用优惠券 0-否 1-是
        goods: [] // 参与砍价的商品数据
    }

    tableData = ['', ''] // 选中的时间数组
    goods_ids = [] // 选中的商品id数组

    isBuyLimit = 0 // 起购限制， 0-不限制，1-限制
    isOrderLimit = 0 // 起购限制， 0-不限制，1-限制

    selectGoods: any = [] //商品选择的商品数据

    formRules = {
        name: [
            {
                required: true,
                message: '请输入活动名称',
                trigger: 'blur'
            }
        ],
        is_distribution: [
            {
                required: true,
                message: '请选择是否参与分销',
                trigger: 'change'
            }
        ],
        buy_condition: [
            {
                required: true,
                message: '请选择购买方式',
                trigger: 'change'
            }
        ],
        valid_period: [
            {
                required: true,
                message: '请输入砍价有效期',
                trigger: 'blur'
            }
        ],
        help_num: [
            {
                required: true,
                message: '请输入帮砍人数',
                trigger: 'blur'
            }
        ],
        count: [
            {
                required: true,
                message: '请输入每人发起次数',
                trigger: 'blur'
            }
        ]
    }
    $refs!: {
        formRef: any
    }
    /** E Data **/

    @Watch('isBuyLimit', {
        immediate: true
    })
    isBuyLimitChange(val: any) {
        if (val == 0) {
            this.$set(this.form, 'buy_limit', 0)
        }
    }
    @Watch('isOrderLimit', {
        immediate: true
    })
    isOrderLimitChange(val: any) {
        if (val == 0) {
            this.$set(this.form, 'order_limit', 0)
        }
    }

    @Watch('form.buy_limit', {
        immediate: true
    })
    buyLimitChange(val: any) {
        if (val == 0) {
            this.isBuyLimit = 0
        } else {
            this.isBuyLimit = 1
        }
    }
    @Watch('form.order_limit', {
        immediate: true
    })
    orderLimitChange(val: any) {
        if (val == 0) {
            this.isOrderLimit = 0
        } else {
            this.isOrderLimit = 1
        }
    }

    /** S Methods **/

    @Watch('selectGoods', { deep: true })
    selectGoodsChange(val: any[]) {
        this.form.goods = val.map((item: any) => {
            return {
                goods_id: item.id,
                items: item.item.map((sitem: any) => ({
                    item_id: sitem.id,
                    first_knife: sitem.first_knife,
                    floor_price: sitem.floor_price
                }))
            }
        })
    }

    checkGoods() {
        const goods = this.form.goods
        if (!goods.length) {
            this.$message.error('请选择砍价商品')
            return false
        }
        for (let i = 0; i < goods.length; i++) {
            for (let j = 0; j < goods[i].items.length; j++) {
                if (!goods[i].items[j].first_knife) {
                    this.$message.error(`请输入砍价商品首刀金额`)
                    return false
                }
                if (!goods[i].items[j].floor_price) {
                    this.$message.error(`请输入砍价商品底价金额`)
                    return false
                }
            }
        }
        return true
    }

    // 表单提交
    onSubmit() {
        // 验证表单格式是否正确
        this.$refs.formRef.validate((valid: boolean): any => {
            if (!valid) {
                return
            }

            // 请求发送
            switch (this.mode) {
                case PageMode.ADD:
                    return this.handleAdd()
                case PageMode.EDIT:
                    return this.handleEdit()
            }
        })
    }

    // 新增
    handleAdd() {
        this.checkGoods()
        apiBargainActivityAdd(this.form)
            .then(() => {
                setTimeout(() => this.$router.go(-1), 500)
            })
            .catch(() => {})
    }

    // 编辑
    handleEdit() {
        this.checkGoods()
        apiBargainActivityEdit({
            ...this.form,
            id: this.identity
        }).then(() => {
            setTimeout(() => this.$router.go(-1), 500)
        })
    }
    // 表单初始化数据 [编辑模式] mode => edit
    initBargainActivityDetail() {
        apiBargainActivityDetail({
            id: this.identity
        })
            .then((res: any) => {
                this.form = res
                this.selectGoods = res.goods
            })
            .catch((err: any) => {})
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        const query: any = this.$route.query

        if (query.mode) {
            this.mode = query.mode
        }

        // 编辑模式：初始化数据
        if (this.mode === PageMode.EDIT) {
            this.identity = query.id * 1
            this.status = query.status * 1
            this.initBargainActivityDetail()

            // let start_time = timeFormat(Number(this.form.start_time))
            // let end_time = timeFormat(Number(this.form.end_time))

            // this.$set(this.form, 'start_time', start_time)
            // this.$set(this.form, 'end_time', end_time)

            // this.tableData[0] = this.form.start_time
            // this.tableData[1] = this.form.end_time
            // this.$forceUpdate()
        }
    }

    /** E Life Cycle **/
}
